/* src/LandingPage.css */

.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height of the viewport */
    padding: 20px;
  }
  
  .store-selector {
    margin-bottom: 20px;
  }
  
  .select-dropdown {
    padding: 12px;
    width: 100%;
    max-width: 300px;
    color: rgb(167, 154, 98); /* Change text color to white or any contrasting color */
    border: none;
    border-radius: 4px;
    appearance: none;
    -webkit-appearance: none;
  }
  
  
  /* Style the selected option */
  .select-dropdown option {
    background-color:white;
    color: rgb(167, 154, 98);
  }
  
  .search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto; /* Center the search bar */
    margin-bottom: 20px;
    border-radius: 10px; /* Add rounded corners */
  }
  
  .search-input {
    /* Add your styles for the search input */
    flex: 1; /* Take up remaining space */
    padding: 12px; /* Adjust padding for a larger size */
    border: none;
    border-radius: 10px; /* Add rounded corners */
    margin-right: 10px; /* Add margin to the right for space */
  }
  
  .search-button {
    /* Add your styles for the search button */
    padding: 12px 20px; /* Adjust padding for a larger size */
    background-color: rgb(167, 154, 98);
    color: white;
    border: none;
    border-radius: 10px; /* Add rounded corners */
    cursor: pointer;
  }
  
  .search-results-container {
    overflow-y: scroll !important;
    height: 100%;
    width: 100%;
  }
  
  .result-table {
    /* Add your styles for the result table */
    border-collapse: collapse;
    width: 100%;
  }
  
  .result-table th, .result-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .result-table th {
    background-color: #f2f2f2;
  }
  
  /* ProductDetails.css */

.product-details {
  margin: 20px;
  border: 1px dashed #ccc;
  border-radius: 10px;
  padding: 10px;
  max-width: auto;
  /* Adjust the maximum width as needed */
  max-height: 380px;
  /* Adjust the maximum height as needed */
}

.product-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-right: 10px;
}

.product-info {
  flex: 1;
}

.product-name {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.product-description {
  margin-bottom: 10px;
}

.product-price,
.product-stock,
.product-availability,
.product-visibility {
  margin-bottom: 5px;
}

.selected-store-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.no-results-message {
  padding: 10px;
  text-align: center;
  font-weight: bold;
  color: red;
}

/* Add this CSS for the clear button */
.clear-button {
  padding: 12px 20px;
  background-color: #ccc;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 10px;
  /* Add margin to separate the clear button from the search button */
}

.results-info,
.search-term-info {
  display: flex;
  margin-bottom: 10px;
}

.results-label,
.search-term-label {
  font-weight: bold;
  margin-right: 5px;
  margin-left: 20px;
}

.results-value,
.search-term-value {
  color: #555;
}

.page-title {
  text-align: center;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 20px 20px 10px;
  /* Adjust the margin as needed */
}

.page-title h1 {
  font-size: 2.5rem;
  /* Adjust the font size as needed */
  font-weight: bold;
  color: rgb(167, 154, 98);
  /* Choose a color that fits your design */
}

.color-icon {
  color: rgb(167, 154, 98);
}

@media (max-width: 600px) {
  /* CSS code for section with width of 600px or less */

  .search-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    /* Set the width to 70% of the screen */
    margin: 0 auto;
    /* Center the search bar */
    margin-bottom: 20px;
    border-radius: 10px;
    /* Add rounded corners */
  }

  .search-input {
    padding: 12px;
    border: none;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 90%;
    margin-right: 0px;
    margin-left: 0px
  }

  .search-button {
    padding: 12px 20px;
    background-color: rgb(167, 154, 98);
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 90%;
    margin-bottom: 10px;
    margin-right: 0px;
    margin-left: 0px
  }

  .clear-button {
    padding: 12px 20px;
    background-color: #ccc;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 90%;
    margin-bottom: 10px;
    margin-right: 0px;
    margin-left: 0px
      /* Add margin to separate the clear button from the search button */
  }


  .product-details {
    display: flex;
    flex-direction: column;
    /* Change display to column */
    margin: 20px;
    border: 1px dashed #ccc;
    border-radius: 10px;
    padding: 10px;
    max-width: auto;
    /* Adjust the maximum width as needed */
    max-height: 380px;
    /* Adjust the maximum height as needed */
  }

  .product-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    -ms-flex-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .product-info {
    flex: 1;
  }

}